import {createStore} from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = {
    login: false,
    passwordChangeRequired: !!JSON.parse(localStorage.getItem('passwordChangeRequired')),
    jwt: null,
    selectedDay: '',
    selectedWeek: {
        from: '',
        to: '',
    },
    userMenu: '',
    userList: null,
    userUuid: '',
    weekOrderList: {},
    orders: [],
    selectedMeal: null,
    modalVisible: false,
    desktopListVisible: false,
    filters: {
        activeFilters: [],
        isMenuActive: false,
        isSaladActive: false
    },
    orderError: false,
    errorDetails: null,
    errorMenus: [],
    sidebarActive: false,
    dessertOrSideDish: '',
    dayDate: ''
}

const store = createStore({
    state,
    actions,
    mutations,
    getters
})
export default store;