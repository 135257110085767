import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import clickOutside from "./directives/clickOutside"
import VueCookies from 'vue3-cookies'
import VueGtag from "vue-gtag";



const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueCookies, {
    expireTimes: '1y'
})
app.directive('click-outside', clickOutside.clickOutside)
app.use(VueGtag, {
    appName: 'Gourmetta Web App',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: process.env.VUE_APP_GA_ID
    }
}, router)
app.mount('#app')
