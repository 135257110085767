<template>
    <div v-if="isLoading">
        <Loader/>
    </div>
    <div v-else>
        <div class="welcome">
            Hallo
        </div>
        <div class="dropdown select"
             ref="dropdown"
             :class="{'is-active':namePickerIsOpen}"
             @click="namePickerIsOpen = !namePickerIsOpen"
             v-click-outside="removeActive"
             v-if="collectiveBuyer"
        >
            <div class="dropdown-trigger">
                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">{{ selected }}</button>
                <UserBadge v-if="isCart"/>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content">
                    <a href="#" class="dropdown-item"
                       :class="{
                            'is-active':userList.uuid === activeUserUuid,
                            'has-pending-changes': hasPendingChanges(userList) && isCart
                        }"
                       @click="selectUser(userList.uuid)">
                        {{ userList.name }}
                    </a>
                    <a href="#" class="dropdown-item"
                       :class="{
                            'is-active':user.uuid === activeUserUuid,
                            'has-pending-changes': hasPendingChanges(user) && isCart
                        }"
                       v-for="(user, index) of userList.accounts" :key="index"
                       @click="selectUser(user.uuid)"> 
                        {{ user.name }} <span class="password-change-required">{{ user.passwordChangeRequired ? ' - Um Bestellungen aufgeben zu können, muss in diesem Kundenkonto ein neues Passwort gesetzt werden (informieren Sie ggf. den Inhaber des Kundenkontos)' : '' }}</span>
                    </a>
                </div>
            </div>
        </div>
        <div v-else class="user-name">
            <span>{{ selected }}</span>
        </div>
        <div class="sub-welcome">
            Schön, Sie zu sehen.
        </div>
    </div>
</template>

<script>
import Loader from "@/views/components/ui/Loader"
import UserBadge from "@/views/components/ui/UserBadge";

export default {
    name: 'UserName',
    props: [
        'isCart'
    ],
    components: {
        Loader,
        UserBadge
    },
    data() {
        return {
            isLoading: true,
            selected: '',
            namePickerIsOpen: false,
            userList: null,
            activeUserUuid: '',
            collectiveBuyer: false
        }
    },
    methods: {
        selectUser(uuid) {
            let activeUser = this.userList
            if (this.userList.uuid !== uuid) {
                activeUser = this.userList.accounts.find(x => x.uuid === uuid)
            }

            if (activeUser.passwordChangeRequired) {
              console.log('Nutzer muss ein neues Passwort setzen')
              return
            }

            this.$store.commit('SET_USER_UUID', uuid)
            this.selected = activeUser.name
            this.$refs.dropdown.classList.remove('is-active')
            this.activeUserUuid = uuid
        },
        removeActive() {
            this.$refs.dropdown.classList.remove('is-active')
        },
        hasPendingChanges(user) {
            return user.modifiedMeals.length > 0
        },
        async getUsers() {
            this.isLoading = true
            this.activeUserUuid = this.$store.getters['getUserUuid']
            let userList = this.$store.getters['getUserList']
            if (userList === null) {
                userList = await this.$store.dispatch('getUserList', this.$store.getters.getUserUuid)
            }
            this.userList = userList
            this.collectiveBuyer = userList.accounts.length > 0

            let loggedUser = this.userList
            if (this.userList.uuid !== this.activeUserUuid) {
                loggedUser = this.userList.accounts.find(x => x.uuid === this.activeUserUuid)
            }
            this.selected = loggedUser.name
            this.isLoading = false
        }
    },
    beforeMount() {
        this.getUsers()
    },
    watch: {
        "$store.state.userList"(userList) {
            this.userList = userList
        }
    }
}
</script>

<style lang="scss" scoped>
.welcome {
    font-size: $font-size-xxlarge;
    font-weight: 900;
    height: 2rem;
}

.dropdown.select {
    height: 2.5rem;
    width: 100%;
    cursor: pointer;

    .dropdown-trigger {
        display: flex;
        width: 90%;
    }

    button {
        padding-left: 0;
        padding-right: 0;
        border: none;
        height: 2rem;
        min-height: 3rem;
        font-size: $font-size-xxlarge;
        font-weight: 900;
        width: 100%;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1rem;
        text-align: left;

        &:active,
        &:hover {
            color: $primary;
        }
    }

    &:not(.is-multiple) {
        height: 2.5rem;
    }

    .dropdown-menu {
        left: -$gap;
        right: -$gap;
        box-shadow: 0 0 $shadow-blur $shadow-color;
        padding-top: 0;

        &:before {
            content: "";
            width: 1.7rem;
            height: 1.7rem;
            background-color: $secondary;
            transform: rotate(45deg);
            position: absolute;
            right: 29px;
            top: -6px;
            z-index: -1;
            box-shadow: $shadow-color -2px -2px $shadow-blur;
        }

        .dropdown-content {
            padding: 0;
            box-shadow: none;

            .dropdown-item {
                padding: $gap;
                border-top: 1px solid $grey;
                font-size: $font-size-medium;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:first-child {
                    border: none;
                }

                &.is-active {
                    font-weight: 900;
                    color: $text;
                    background-color: $grey;
                }

                &.has-pending-changes {
                    background-color: $yellow;
                }
            }
        }
    }
}

.user-name {
    span {
        font-size: $font-size-xxlarge;
        font-weight: 900;
        height: 2.5rem;
    }
}

.sub-welcome {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    color: $dark-grey;
    margin-bottom: 3rem;
    margin-top: .4rem;
}

.password-change-required {
    color: $red;
}

@media all and (min-width: $desktop) {
    .sub-welcome {
        margin-bottom: 1rem;
    }
    .select {
        &:not(.is-multiple) {
            &:not(.is-loading) {
                &::after {
                    right: 10px;
                }
            }
        }
    }

}
</style>
